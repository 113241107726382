/* global window, Map */

/**
 * Ultra simple class to store and retrieve LeafletJS map instances
 * 
 * AlpineJS's proxying causes issues with LeafletJS's event listeners (map object isn't properly assigned to objects 
 * like popups and tooltips). 
 * 
 * Unfortunately, unlike VueJS, there's no "shallowRef" or "toRaw" method available through AlpineJS (at least not 
 * this version)
 */
class MapTracker {

	
	mapInstances = new Map()


	/**
	 * Save map instance
	 * 
	 * @param {string} id Map instance ID
	 * @param {object} map LeafletJS map object
	 * 
	 * @return {void}
	 */
	setInstance( id, map ) {
		this.mapInstances.set( id, map )
	}


	/**
	 * Retrieve map instance
	 *
	 * @param {string} id Map instance ID
	 * @return {object|undefined} LeafletJS object if it exists; otherwise, undefined
	 */
	getInstance( id ) {
		return this.mapInstances.get( id )
	}
	
}


// singleton method
const instance = new MapTracker

export default instance