import L from 'leaflet'


// wrapper for LeafletJS functionality to avoid conflicts with AlpineJS
export class MapInstance {


	#leafletMap
	#mapTooltip


	/**
	 * Create map instance
	 *
	 * @param {HTMLElement} node HTML node to apply map to
	 * @param {object} baseBounds LatlngBounds
	 * @param {string} image URL for background image
	 * 
	 * @return {obj}
	 */
	constructor( node, baseBounds, image ) {

		// by default, L.CRS.Simple uses bottom left corner as origin point; we want the top left corner
		const crsSimpleCorrected = L.extend({}, L.CRS.Simple, {
			transformation: new L.Transformation( 1, 0, 1, 0 ),
		})

		this.#leafletMap = L.map( node, {
			attributionControl: false,
			crs: crsSimpleCorrected,
			doubleClickZoom: false,
			dragging: false,
			inertia: false,
			maxBounds: baseBounds,
			maxBoundsViscosity: 1,
			maxZoom: 2,
			minZoom: -1,
			scrollWheelZoom: false,
			zoomControl: false, // we'll use our own zoom controls
			zoomSnap: 0.1,
		})

		L.imageOverlay( image, baseBounds ).addTo( this.#leafletMap )
	}


	/**
	 * Get Leaflet map
	 *
	 * @return {object} Leaflet map
	 */
	getLeafletMap() {
		return this.#leafletMap
	}


	/**
	 * Open tooltip on map
	 *
	 * @param {string} content 
	 * @param {object} latLng
	 * 
	 * @return {void}
	 */
	openTooltip( content, latLng ) {

		// close any preexisting tooltips
		this.closeTooltip()

		this.#mapTooltip = L.tooltip({
			className: 'room-popup',
			direction: 'top',
		}).setLatLng( latLng ).setContent( content ).addTo( this.#leafletMap )
	}


	/**
	 * Close tooltip
	 *
	 * @return {void}
	 */
	closeTooltip() {
		if( this.#mapTooltip ) {
			this.#mapTooltip.remove()
		}
	}


}