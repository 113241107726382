/* global window, document, jQuery */
import L from 'leaflet'
import { convertStringToLatLngCoords } from '../utils/convertStringToCoordinates'
import '../plugins/wise-leaflet-pip'

import MapTracker from './MapTracker'
import { MapInstance } from '../models/MapInstance'


const $ = jQuery


export const FloorPlan = () => ({


	data: {
		rooms: [],
		
		// sidebar
		defaultContent: {},

		content: {
			heading: '',
			subheading: '',
			description: '',
			imageUrl: '',
			accentColor: '',
		},

		// mobile
		activeDropdownRoom: null,
		activeDropdownRoomItem: null,
		dropdownRoomItemOptions: [],

		// desktop
		activeRoom: null,
		activeRoomItemMarkers: [],

		map: {
			imageUrl: '',
			baseBounds: L.latLngBounds(
				L.latLng( 0, 0 ),
				L.latLng( 1000, 1000 ),
			),
			polygonDefaultStyles: {
				stroke: false,
				color: 'transparent',
				fill: true,
				fillColor: 'transparent',
			},
			polygonActiveStyles: {
				stroke: true,
				color: '#FFAF3B',
				weight: 2,
			},
		},
		
		zoomedIn: false, // controls visibility of zoom out button
	},


	/**
	 * Kicks off Alpine functionality
	 *
	 * @return {void}
	 */
	init() {
		const { defaultContent, map, rooms } = window.webfxInteractiveFloorPlanData

		this.data.map.imageUrl = map.url
		this.data.rooms = rooms
		this.data.defaultContent = defaultContent

		// dispatch event for animation
		this.$watch( 'data.content.heading', () => {
			const eventContentChange = new CustomEvent( 'interactive_floor_plan:content_change', {
				detail: this.data.content
			})

			this.$refs.nodeFloorPlan.dispatchEvent( eventContentChange )
		})

		// needs to be below the above watcher to ensure initial content change
		this.displayDefaultContent()

		// Trigger animation when text content changes
		this.$refs.nodeFloorPlan.addEventListener('interactive_floor_plan:content_change', (e) => {
			this.handleContentChangeAnimation(e.detail);
		});

		// only render map on desktop
		this.$nextTick( () => {
			const mql = window.matchMedia( '(min-width: 1200px)' )

			// if desktop, render map
			if( mql.matches ) {
				this.renderMap()
			
			// if mobile, only render if viewport width becomes greater than 1200px
			} else {
				const maybeRender = e => {
					if( e.matches ) {
						this.renderMap()
						mql.removeEventListener( 'change', maybeRender )
					}
				}

				mql.addEventListener( 'change', maybeRender )
			}
		})

		this.$watch( 'data.activeDropdownRoom', roomName => this.handleDropdownRoomSelect( roomName ) )
		this.$watch( 'data.activeDropdownRoomItem', itemName => this.handleDropdownRoomItemSelect( itemName ) )
	},

	/**
	 * Trigger global animation when content changes
	 *
	 * @param {object} content
	 * @return {void}
	 */
	handleContentChangeAnimation(content) {

		// Ensure animation only triggers when there is actual content
		if (content.heading || content.description) {
			FX.Animation.interactiveFloorPlanTextOnly();

			$('.leaflet-container').addClass('is-zoomed-container-active');
			$('.leaflet-marker-pane').addClass('is-zoomed-active');
			$('.leaflet-map-pane').addClass('is-map-pane-active');
		}
	},

	/**
	 * Displays default content in info sidebar
	 *
	 * @return {void}
	 */
	displayDefaultContent() {
		this.updateDescriptiveContent( 
			this.data.defaultContent.heading, 
			this.data.defaultContent.subheading, 
			this.data.defaultContent.description,
		)
	},


	/**
	 * Get Leaflet map from map instance
	 * 
	 * We're intentionally not assigning Leaflet map instances to this.data as AlpineJS's proxying has caused a slew 
	 * of issues
	 *
	 * @return {object|false} Object, if valid map; otherwise, false
	 */
	getMapInstance() {
		return MapTracker.getInstance( this.$refs.nodeInteractiveFloorPlan.dataset.floorPlanInstanceId ) || false
	},


	/**
	 * Update content in sidebar (on desktop) and underneath dropdowns (on mobile)
	 *
	 * @param {string} heading
	 * @param {string} subheading
	 * @param {string} description
	 * @param {string} imageUrl
	 * @param {string} accentColor
	 * 
	 * @return {void}
	 */
	updateDescriptiveContent( heading = '', subheading = '', description = '', imageUrl = '', accentColor = '' ) {

		// we need at least a heading and description; otherwise, display default content
		if( !heading || !description ) {
			heading = this.data.defaultContent.heading
			subheading = this.data.defaultContent.subheading
			description = this.data.defaultContent.description
			imageUrl = ''
			accentColor = ''
		}

		this.data.content.heading = heading
		this.data.content.subheading = subheading
		this.data.content.description = description
		this.data.content.imageUrl = imageUrl

		// add/remove accent color from node
		if( accentColor.length ) {
			this.$refs.nodeInteractiveFloorPlan.style.setProperty( '--accent-color', `var( --color-${accentColor} )` )
		} else {
			this.$refs.nodeInteractiveFloorPlan.style.removeProperty( '--accent-color' )
		}
	},


	/**
	 * Render floor plan as Leaflet map
	 *
	 * @return {void}
	 */
	renderMap() {
		const mapInstance = new MapInstance( 
			this.$refs.nodeFloorPlan, 
			this.data.map.baseBounds, 
			this.data.map.imageUrl 
		)

		/**
		 * Save map instance for later usage. We're intentionally assigning this map to something outside of AlpineJS 
		 * to circumvent issues with AlpineJS's proxying behavior and LeafletJS's event listeners
		 */
		MapTracker.setInstance( this.$refs.nodeInteractiveFloorPlan.dataset.floorPlanInstanceId, mapInstance )

		this.renderRooms()

		mapInstance.getLeafletMap().fitBounds( this.data.map.baseBounds )
	},


	/**
	 * Render outlines for rooms on map
	 *
	 * @return {void}
	 */
	renderRooms() {
		const mapInstance = this.getMapInstance()

		if( mapInstance ) {
			this.data.rooms.forEach( room => {
				room.bounds = convertStringToLatLngCoords( room.coordinates )
				room.outline = L.polygon( room.bounds, this.data.map.polygonDefaultStyles )
	
				room.outline
					.addTo( mapInstance.getLeafletMap() )
					.on( 'click', () => this.handleRoomMarkerClick( room ) )
					.on( 'mouseover', () => this.handleRoomMarkerHoverIn( room ) )
					.on( 'mouseout', () => this.handleRoomMarkerHoverOut( room ) )
			})
		}
	},


	/**
	 * Handle user clicking room outline
	 *
	 * @param {object} room
	 * @return {void}
	 */
	handleRoomMarkerClick( room ) { 

		// don't do anything if we don't have a map instance
		const mapInstance = this.getMapInstance()

		if( !mapInstance ) {
			return
		}

		// don't do anything if we're already zoomed into an active room
		if( this.data.activeRoom ) {
			return
		}

		// center floor plan in browser window
		this.$refs.nodeFloorPlan.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})

		// remove marker items from current room
		this.clearActiveRoomItemMarkers()

		this.data.activeRoom = room

		// remove active styles to room outline
		room.outline.setStyle( this.data.map.polygonDefaultStyles )

		const leafletMap = mapInstance.getLeafletMap()

		// zoom into room
		leafletMap.fitBounds( L.latLngBounds( room.bounds ) )

		// add markers to map for room items
		if( room.items ) {
			this.addRoomItemMarkers( room.items )
		}

		// update sidebar; if room doesn't have information, this'll fallback on default content
		this.updateDescriptiveContent( room.heading, room.subheading, room.content, '' )
		
		// forces zoom out button to display
		this.data.zoomedIn = true

		const handleOutsideRoomClick = ({ latlng }) => {
			if( this.data.activeRoom && !this.data.activeRoom.outline.contains( latlng ) ) {
				this.resetMap()
				leafletMap.off( 'click', handleOutsideRoomClick )
			}
		}

		// if user clicks outside of room, zoom out and reset view
		leafletMap.on( 'click', handleOutsideRoomClick )
	},
	

	/**
	 * Handle user hovering inside room outline
	 *
	 * @param {object} room
	 * @return {void}
	 */
	handleRoomMarkerHoverIn( room ) {
		
		// don't apply styling if another room is already active
		if( this.data.activeRoom ) {
			return
		}

		room.outline.setStyle( this.data.map.polygonActiveStyles )

		const mapInstance = this.getMapInstance()

		if( mapInstance ) {
			mapInstance.openTooltip( room.name, room.outline.getCenter() )
		}
	},


	/**
	 * Handle user hovering outside of room outline
	 *
	 * @param {object} room
	 * @return {void}
	 */
	handleRoomMarkerHoverOut( room ) {
		const mapInstance = this.getMapInstance()

		if( mapInstance ) {
			mapInstance.closeTooltip()
		}

		// only reset styles if room isn't active
		if( !this.data.activeRoom || room.id !== this.data.activeRoom.id ) {
			room.outline.setStyle( this.data.map.polygonDefaultStyles )
		}
	},


	/**
	 * Add outlines for room items to map
	 *
	 * @param {array} items Room items
	 * @return {void}
	 */
	addRoomItemMarkers( items ) {
		const mapInstance = this.getMapInstance()

		if( mapInstance ) {
			items.forEach( item => {
					
				// HTML for pulsator button
				const iconHtml = document.createElement( 'span' )
	
				iconHtml.classList.add( 'room-item-marker__pulsator' )
				iconHtml.classList.add( `room-item-marker__pulsator--${item.accent_color }` )
				iconHtml.style.setProperty( '--pulsator-color', `var( --color-${item.accent_color} )` )
	
				const icon = L.divIcon({
					iconSize: L.point( 50, 50 ),
					className: 'room-item-marker',
					html: iconHtml,
				})
	
				const marker = L.marker( L.latLng( item.y_coords, item.x_coords ), { 
					icon
				})
	
				marker
					.addTo( mapInstance.getLeafletMap() )
					.on( 'click', () => {
						this.updateDescriptiveContent( 
							item.heading, 
							item.subheading, 
							item.content, 
							item.image_url,
							item.accent_color
						)
					})
	
				this.data.activeRoomItemMarkers.push( marker )
			})
		}
	},


	/**
	 * Clear markers for room items
	 *
	 * @return {void}
	 */
	clearActiveRoomItemMarkers() {
		this.data.activeRoomItemMarkers.forEach( marker => marker.remove() )
		this.data.activeRoomItemMarkers = []
	},


	/**
	 * Clear active room by resetting style
	 *
	 * @return {void}
	 */
	clearActiveRoom() {
		if( this.data.activeRoom ) {
			this.data.activeRoom.outline.setStyle( this.data.map.polygonDefaultStyles )
			this.data.activeRoom = null
		}
	},


	/**
	 * Reset map view by scrolling to fit entire map and enable dragging
	 *
	 * @return {void}
	 */
	resetMapView() {
		const mapInstance = this.getMapInstance()

		if( mapInstance ) {
			mapInstance.getLeafletMap().fitBounds( this.data.map.baseBounds )
		}
	},


	/**
	 * Handle user clicking "zoom out" button
	 *
	 * @return {void}
	 */
	handleZoomOutClick() {
		this.resetMap()

		// Remove animation classes when zooming out
		$('.room-item-marker').removeClass('is-active');
		$('.leaflet-marker-pane').removeClass('is-zoomed-active');
		$('.leaflet-map-pane').removeClass('is-zoomed-active');
		$('.leaflet-container').removeClass('is-zoomed-container-active');
	},


	/**
	 * Reset map
	 *
	 * @return {void}
	 */
	resetMap() {
		this.clearActiveRoomItemMarkers()
		this.clearActiveRoom()
		this.resetMapView()
		this.displayDefaultContent()

		console.log( 'firing' )

		this.$nextTick( () => this.data.zoomedIn = false )

		// Remove animation classes when resetting map
		$('.room-item-marker').removeClass('is-active');
		$('.leaflet-marker-pane').removeClass('is-zoomed-active');
		$('.leaflet-map-pane').removeClass('is-zoomed-active');
		$('.leaflet-container').removeClass('is-zoomed-container-active');
	},


	/**
	 * Handle user selecting room from mobile dropdown and display items associated with room
	 *
	 * @param {string} selectedRoomName
	 * @return {void}
	 */
	handleDropdownRoomSelect( selectedRoomName ) {
		const refreshedOptions = []

		if( 'string' === typeof selectedRoomName && selectedRoomName.length ) {
			const selectedRoom = this.data.rooms.find( room => selectedRoomName === room.name )

			if( selectedRoom ) {
				selectedRoom.items.forEach( item => refreshedOptions.push( item ) )

				this.updateDescriptiveContent(
					selectedRoom.heading,
					selectedRoom.subheading,
					selectedRoom.content,
				)
			}
		}

		this.data.dropdownRoomItemOptions = refreshedOptions
	},


	/**
	 * Handle user selecting room item from mobile dropdown and display information associated with room item
	 *
	 * @param {string} selectedRoomItemName
	 * @return {void}
	 */
	handleDropdownRoomItemSelect( selectedRoomItemName ) {
		if( !this.data.activeDropdownRoom || !this.data.activeDropdownRoomItem ) {
			this.displayDefaultContent()

		} else {
			const selectedRoom = this.data.rooms.find( room => this.data.activeDropdownRoom === room.name )

			if( selectedRoom ) {
				const selectedItem = selectedRoom.items.find( item => selectedRoomItemName === item.name )

				if( selectedItem ) {
					this.updateDescriptiveContent( 
						selectedItem.heading, 
						selectedItem.subheading, 
						selectedItem.content, 
						selectedItem.imageUrl,
						selectedItem.accent_color,
					)
				}
			}
		}
	},

})