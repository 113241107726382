import L from 'leaflet'


/**
 * Convert string of space-delimited coordinates to an array of LatLng objects
 *
 * @param {string} coordStr String of space-delimited coordinates
 * @return {array} Array of LatLng objects
 */
export const convertStringToLatLngCoords = coordStr => {
	const items = coordStr.split( ' ' )

	let pairs = []

	for( let i = 0; i < items.length; i += 2 ) {
		const xCoord = parseFloat( items[i] )
		const yCoord = parseFloat( items[i + 1] )

		pairs.push( [ yCoord, xCoord ] )
	}

	pairs = pairs.map( pair => L.latLng( pair[0], pair[1] ) )

	return pairs
}